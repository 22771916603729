import DynamicPage from "components/pages/dynamic/dynamic-page.vue";
import { trackEvent } from "services/analytics";
import { createApp } from "vue";
import { mountApp } from "vue-app";

const app = createApp(DynamicPage,
    {
        cmsPageEndpointPath: "/index"
    }
);
mountApp(app, "page-app");

trackEvent("entry_view", { item_name: "Virtuoso_Home", item_category: "Virtuoso_Homepage" });
